<!--字典管理-->
<template>
  <page-container title="违约情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="字典类型" prop="dictype">
              <a-select v-model="queryParams.dictype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(dic_type_desc, index) in dicTypeDescArray" :key="index"
                                 :value="dic_type_desc.code">{{ dic_type_desc.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="字典值" prop="dicvalue">
              <a-input v-model="queryParams.dicvalue" placeholder="请输入字典值"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="字典Key" prop="dickey">
              <a-input v-model.trim="queryParams.dickey" placeholder="请输入字典Key"></a-input>
            </a-form-model-item>
            <a-form-model-item label="字典描述" prop="dicdesc">
              <a-input v-model.trim="queryParams.dicdesc" placeholder="请输入字典描述"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">字典列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
<!--          <span slot="dictype" slot-scope="text" class="status-view">-->
<!--            <span class="text">{{ dicTypeDescMap[text] }}</span>-->
<!--          </span>-->
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'edit-'+record.dicid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.dicid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改违约页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button v-if="modalType==='add'||modalType==='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">

        <a-form-model-item label="字典类型" prop="dictype">
          <a-select v-model="modalForm.dictype">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option v-for="(dic_type_desc, index) in dicTypeDescArray" :key="index" :value="dic_type_desc.code">
              {{ dic_type_desc.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="字典Key" prop="dickey">
          <a-input v-model.trim="modalForm.dickey" placeholder="请输入字典Key"/>
        </a-form-model-item>
        <a-form-model-item label="字典值" prop="dicvalue">
          <a-input v-model="modalForm.dicvalue" placeholder="请输入字典值"></a-input>
        </a-form-model-item>
        <a-form-model-item label="字典描述" prop="dicdesc">
          <a-input v-model="modalForm.dicdesc" placeholder="请输入字典描述"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addDictionary,
  getDictionaryListByCondition,
  deleteOneDictionary,
  editDictionary,
  getDictionaryTypeListByCondition,
} from 'A/xtpz'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'

export default {
  name: 'dictionaryManagement',
  mixins: [deptselect],
  data() {
    return {
      showAdvanced: false,
      moment,
      dicTypeDescArray: [],
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '字典管理',
          path: ''
        },
        {
          name: '字典管理',
          path: ''
        }
      ],
      queryParams: {
        dictype: '',
        dickey: '',
        dicvalue: '',
        dicdesc: '',
      },
      tableColumns: [
        {
          title: '字典类型',
          dataIndex: 'name',
          key: 'name',
          //scopedSlots: {customRender: 'dictype'}
        },
        {
          title: '字典类型编码',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: '字典Key',
          dataIndex: 'dickey',
          key: 'dickey'
        },
        {
          title: '字典值',
          dataIndex: 'dicvalue',
          key: 'dicvalue'
        },
        {
          title: '字典描述',
          dataIndex: 'dicdesc',
          key: 'dicdesc',

        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        name:'',
        code:'',
        dicid: 0,
        dictype: '',
        dickey: '',
        dicvalue: '',
        dicdesc: '',
      },
      modalRules: {
        dictype: [{required: true, message: '请选择字典类型'}],
        dickey: [{required: true, message: '请输入字典Key'}],
        dicvalue: [{required: true, message: '请输入字典值'}],
      },
      selectedDictionary: null
    }
  },
  computed: {
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {

  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.getAllDicType();
      this.getTableData();
    },
    getAllDicType(){
      let params = {};
      getDictionaryTypeListByCondition(params).then(res => {
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.dicTypeDescArray = res.item ? res.item : []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      console.log(params)
      getDictionaryListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item ? res.item : []
          this.pagination.total = res.count;
        }
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.dicid = 0
        this.modalForm.dictype = ''
        this.modalForm.dickey = ''
        this.modalForm.dicvalue = ''
        this.modalForm.dicdesc = ''
      } else {
        this.modalVisible = true;
        this.modalForm.dicid = record.dicid
        this.modalForm.dictype = record.dictype
        this.modalForm.dickey = record.dickey
        this.modalForm.dicvalue = record.dicvalue
        this.modalForm.dicdesc = record.dicdesc
      }
    },
    deleteConfirm(value, record) {
      this.$confirm({
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.delete(record.dicid);
        },
        onCancel() {
        },
      });
    },
    delete(id) {
      if (id) {
        let params = {
          id
        };
        deleteOneDictionary(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let params = {
        dictype: this.modalForm.dictype,
        dickey: this.modalForm.dickey,
        dicvalue: this.modalForm.dicvalue,
        dicdesc: this.modalForm.dicdesc,
      };
      if (this.modalType == 'add') {
        console.log(params)
        addDictionary(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.dicid = this.modalForm.dicid;
        console.log(params)
        editDictionary(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      console.log(key)
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedDictionary = getItemFromArrayByKey(this.tableData, 'dicid', id);
      console.log(this.selectedDictionary)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedDictionary)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedDictionary)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
</style>